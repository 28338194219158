@import "../../styles/palette.scss";
@import "../../styles/functions.scss";
.header {
  background-color: $dark-blue;
  padding-top: calc-rem(75px);
  padding-bottom: calc-rem(55px);

  &__link {
    padding-left: 8px;
    padding-right: 8px;
    color: $white;
    text-decoration: none;

    &:hover {
      color: $primary-blue;
      cursor: pointer;
    }
  }
}
