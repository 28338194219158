@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");

@import "styles/palette.scss";
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Pathway Gothic One", sans-serif;
  line-height: 1 !important;
  overflow-x: hidden;
}

.image {
  width: 100%;
  height: 100%;
}

.container {
  padding-left: 25px;
  padding-right: 25px;

  &--noPadding {
    padding: 0;
  }

  &__content {
    max-width: 1500px;
    margin: auto;
  }
}
.link {
  text-decoration: none;
  &:hover {
    background-color: transparent !important;
    cursor: pointer;
  }
}

.carousel-next:before {
  filter: invert(43%) sepia(91%) saturate(2560%) hue-rotate(168deg)
    brightness(99%) contrast(101%);
}
.carousel-prev:before {
  filter: invert(43%) sepia(91%) saturate(2560%) hue-rotate(168deg)
    brightness(99%) contrast(101%);
}
