@import "../../styles/palette.scss";
@import "../../styles/functions.scss";

.footer {
  background-color: $dark-blue;
  padding-top: calc-rem(75px);
  padding-bottom: calc-rem(55px);

  &__title {
    text-transform: capitalize;
    color: white !important;

    &::after {
      position: absolute;
      content: "";
      display: block;
      background: $primary-blue;
      width: calc-rem(35px);
      height: calc-rem(3px);
      margin-top: calc-rem(15px);
    }
  }

  &__listItem {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }

  &__link {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $primary-blue;
      cursor: pointer;
    }
  }
}
